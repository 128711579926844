import React, { useEffect } from 'react';
import './Home.css';
import Header from '../../Components/Header/Header';
import HomeGames from '../../Components/HomeGames/HomeGames';
import Footer from '../../Components/Footer/Footer';
import AboutSection from '../../Components/AboutSection/AboutSection';
import { Adsense } from '@ctrl/react-adsense';
import AdComponent from '../../Components/AdComponent/AdComponent';

function Home() {
    return (
        <>
            <div className='Main_Bg'>
                <div className='content-section'>
                    <Header />
                    <div className='p-3'>
                        <AdComponent
                            client="ca-pub-2248973218739803"
                            slot="9826680987"
                            format="auto"
                            style={{ display: 'block', width: '100%', height: '280px' }}
                        />
                        <div className='px-2'>
                            <div className='custom-card mt-4 py-4'>
                                <div className='custom-heading h4'>Play online free poki games</div>
                                <a href="https://www.playpokigames.com" ><button className='mt-4 p-2 px-4 custom-button'>Click to play</button></a>
                            </div>
                            <div className='custom-card mt-4 py-4'>
                                <div className='custom-heading h4'>Prefer games which you want to play</div>
                                <a href="https://www.playpokigames.com" ><button className='mt-4 custom-button p-2 px-4'>Car Games</button></a>
                                <a href="https://www.playpokigames.com" ><button className='mt-4 ms-2 custom-button p-2 px-4'>Puzzle Games</button></a>
                            </div>
                        </div>
                        <div className='pt-4'>
                            <HomeGames />
                        </div>
                        <div className='px-2 my-4'>
                            <a href="https://www.playpokigames.com" ><button className='custom-button w-100 py-3'>More Games</button></a>
                        </div>
                        <AdComponent
                                        client="ca-pub-2248973218739803"
                                        slot="1462203285"
                                        format="autorelaxed"
                                        style={{ display: 'block' }}
                                    />
                        {/* <AboutSection /> */}
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Home